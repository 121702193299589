//TODO: This should be moved to @bannerflow/material
import { Component, Directive, OnChanges, Input, Output, ElementRef, EventEmitter, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ColorService, Hsla, Hsva, Rgba, SliderDimension, SliderPosition } from './color.service';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'colorPicker',
    styleUrls: ['./colorPicker.component.scss'],
    templateUrl: './colorPicker.component.html',
    standalone: true,
    imports: [forwardRef(() => SliderDirective), CommonModule, forwardRef(() => ColorTextDirective)]
})
export class ColorPickerComponent implements OnInit, OnChanges {
    public isEditing: boolean;

    public hsva: Hsva;
    public rgbaText: Rgba;
    public hslaText: Hsla;
    public hexText: string;
    public selectedColor: string;
    public alphaSliderColor: string;
    public hueSliderColor: string;
    public slider: SliderPosition;
    private sliderDimMax: SliderDimension;
    private format: number;
    private outputColor: string;

    // private listenerMouseDown: any;
    // private listenerResize: any;

    // private cpPosition: string;
    // private cpPositionOffset: number;
    private cpOutputFormat: string;
    // private cpPresetColors: Array<string>;
    // private cpHeight: number;
    // private cpWidth: number = 280;
    // private cpIgnoredElements: any;
    private cpAlphaChannel: string = 'hex6';

    @ViewChild('hueSlider', { static: true }) hueSlider: ElementRef;
    @ViewChild('alphaSlider', { static: true }) alphaSlider: ElementRef;
    @ViewChild('saturationSlider', { static: true }) saturationSlider: ElementRef;
    @ViewChild('dialogPopup', { static: true }) dialogElement: ElementRef;

    @Input('config') config: any;
    @Input('color') color: string;
    @Input('initialColor') initialColor: string; //Way to set uniqeu default color
    @Output('colorChange') colorChange = new EventEmitter<string>(true);
    @Output('end') end = new EventEmitter<string>(true);
    //@Output('')


    constructor(private el: ElementRef, private service: ColorService) { }

    ngOnChanges(changes: any): void {
        if (changes.color && !this.isEditing && this.service.validateColor(changes.color.currentValue)) {
            this.setInitialColor(changes.color.currentValue);
            this.setColorFromString(changes.color.currentValue, false);
        }
    }

    ngOnInit() {
        //If no config is passed -> use default settings
        if(!this.config) {
            this.config = new ColorPickerConfig()
        }

        let alphaWidth = this.alphaSlider.nativeElement.offsetWidth;
        let hueWidth = this.hueSlider.nativeElement.offsetWidth;
        let saturationWidth = this.saturationSlider.nativeElement.offsetWidth;
        let saturationHeight = this.saturationSlider.nativeElement.offsetHeight;

        //Containement size for all sliders.
        this.sliderDimMax = new SliderDimension(hueWidth, saturationWidth, saturationHeight, alphaWidth);
        this.slider = new SliderPosition(0, 0, 0, 0);

        if (this.cpOutputFormat === 'rgba') {
            this.format = 1;
        } else if (this.cpOutputFormat === 'hsla') {
            this.format = 2;
        } else {
            this.format = 0;
        }

        this.setInitialColor('#000000');
        this.setColorFromString(this.initialColor, false);
    }

    public onStartEditing() {
        this.isEditing = true;
    }
    public onStopEditing() {
        this.isEditing = false;
        this.end.emit(this.outputColor);
    }

    setInitialColor(color: any) {
        this.initialColor = this.initialColor || color;
    }

    setColorFromString(value: string, emit: boolean = true) {
        let hsva: Hsva;
        if (this.cpAlphaChannel === 'hex8') {
            hsva = this.service.stringToHsva(value, true);
            if (!hsva && !this.hsva) {
                hsva = this.service.stringToHsva(value, false);
            }
        } else {
            hsva = this.service.stringToHsva(value, false);
        }

        if (hsva) {
            this.hsva = hsva;
            this.update(emit);
        }
    }

    setSaturation(val: { v: number, rg: number }) {
        let hsla = this.service.hsva2hsla(this.hsva);
        hsla.s = val.v / val.rg;
        this.hsva = this.service.hsla2hsva(hsla);
        this.update();
    }

    setLightness(val: { v: number, rg: number }) {
        let hsla = this.service.hsva2hsla(this.hsva);
        hsla.l = val.v / val.rg;
        this.hsva = this.service.hsla2hsva(hsla);
        this.update();
    }

    setHue(val: { v: number, rg: number }) {
        this.hsva.h = val.v / val.rg;
        this.update();
    }

    setAlpha(val: { v: number, rg: number }) {
        this.hsva.a = val.v / val.rg;
        this.update();
    }

    setR(val: { v: number, rg: number }) {
        let rgba = this.service.hsvaToRgba(this.hsva);
        rgba.r = val.v / val.rg;
        this.hsva = this.service.rgbaToHsva(rgba);
        this.update();
    }
    setG(val: { v: number, rg: number }) {
        let rgba = this.service.hsvaToRgba(this.hsva);
        rgba.g = val.v / val.rg;
        this.hsva = this.service.rgbaToHsva(rgba);
        this.update();
    }
    setB(val: { v: number, rg: number }) {
        let rgba = this.service.hsvaToRgba(this.hsva);
        rgba.b = val.v / val.rg;
        this.hsva = this.service.rgbaToHsva(rgba);
        this.update();
    }

    setSaturationAndBrightness(val: { s: number, v: number, rgX: number, rgY: number }) {

        this.hsva.s = val.s / val.rgX;
        this.hsva.v = val.v / val.rgY;

        this.update();
    }

    formatPolicy(event): number {
        this.format = (this.format + 1) % 2;
        if (this.format === 0 && this.hsva.a < 1 && this.cpAlphaChannel === 'hex6') {
            this.format++;
        }
        return this.format;
    }

    update(emit: boolean = true) {

        //Make sure this is initiated before updating
        if(!this.sliderDimMax) {
            return;
        }

        let hsla = this.service.hsva2hsla(this.hsva);
        let rgba = this.service.denormalizeRGBA(this.service.hsvaToRgba(this.hsva));
        let hueRgba = this.service.denormalizeRGBA(this.service.hsvaToRgba(new Hsva(this.hsva.h, 1, 1, 1)));

        this.hslaText = new Hsla(Math.round((hsla.h) * 360), Math.round(hsla.s * 100), Math.round(hsla.l * 100), Math.round(hsla.a * 100) / 100);
        this.rgbaText = new Rgba(rgba.r, rgba.g, rgba.b, Math.round(rgba.a * 100) / 100);
        this.hexText = this.service.hexText(rgba, this.cpAlphaChannel === 'hex8');

        this.alphaSliderColor = 'rgb(' + rgba.r + ',' + rgba.g + ',' + rgba.b + ')';
        this.hueSliderColor = 'rgb(' + hueRgba.r + ',' + hueRgba.g + ',' + hueRgba.b + ')';

        if (this.format === 0 && this.hsva.a < 1 && this.cpAlphaChannel === 'hex6') {
            this.format++;
        }

        let lastOutput = this.outputColor;

        this.outputColor = this.service.outputFormat(this.hsva, this.cpOutputFormat, this.cpAlphaChannel === 'hex8');

        this.selectedColor = this.service.outputFormat(this.hsva, 'rgba', false);

        this.slider = new SliderPosition((this.hsva.h) * this.sliderDimMax.h - 8, this.hsva.s * this.sliderDimMax.s - 8,
            (1 - this.hsva.v) * this.sliderDimMax.v - 8, this.hsva.a * this.sliderDimMax.a - 8)

        if (emit && lastOutput !== this.outputColor) {
            this.colorChange.emit(this.outputColor);
        }
    }

    isDescendant(parent: any, child: any): boolean {
        let node: any = child.parentNode;
        while (node !== null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    createBox(element: any, offset: boolean): any {
        return {
            top: element.getBoundingClientRect().top + (offset ? window.pageYOffset : 0),
            left: element.getBoundingClientRect().left + (offset ? window.pageXOffset : 0),
            width: element.offsetWidth,
            height: element.offsetHeight
        };
    }
}

@Directive({
    selector: '[slider]',
    host: {
        '(mousedown)': 'start($event)',
        '(touchstart)': 'start($event)'
    },
    standalone: true
})
export class SliderDirective {
    @Output('newValue') newValue: EventEmitter<any> = new EventEmitter<any>();
    @Output('startEditing') startEditing: EventEmitter<any> = new EventEmitter<any>();
    @Output('stopEditing') stopEditing: EventEmitter<any> = new EventEmitter<any>();

    @Input('slider') slider: string;
    @Input('rgX') rgX: number;
    @Input('rgY') rgY: number;

    public isDragging: boolean;
    private listenerMove: any;
    private listenerStop: any;

    constructor(private el: ElementRef) {
        this.listenerMove = (event: any) => { this.move(event) };
        this.listenerStop = () => { this.stop() };
    }

    setCursor(event: any) {
        let height = this.el.nativeElement.offsetHeight;
        let width = this.el.nativeElement.offsetWidth;
        let x = Math.max(0, Math.min(this.getX(event), width));
        let y = Math.max(0, Math.min(this.getY(event), height));

        if (this.rgX !== undefined && this.rgY !== undefined) {
            this.newValue.emit({ s: x / width, v: (1 - y / height), rgX: this.rgX, rgY: this.rgY });

        } else if (this.rgX === undefined && this.rgY !== undefined) {//ready to use vertical sliders
            this.newValue.emit({ v: y / height, rg: this.rgY });
        } else {
            this.newValue.emit({ v: x / width, rg: this.rgX });
        }
    }

    move(event: any) {
        event.preventDefault();
        //event.stopPropagation();
        this.setCursor(event);
    }

    start(event: any) {
        this.isDragging = true;
        this.setCursor(event);
        document.addEventListener('mousemove', this.listenerMove);
        document.addEventListener('touchmove', this.listenerMove);
        document.addEventListener('mouseup', this.listenerStop);
        document.addEventListener('touchend', this.listenerStop);
        //event.stopPropagation();
        //event.preventDefault();
        this.startEditing.emit()
    }

    stop() {
        this.isDragging = false;
        document.removeEventListener('mousemove', this.listenerMove);
        document.removeEventListener('touchmove', this.listenerMove);
        document.removeEventListener('mouseup', this.listenerStop);
        document.removeEventListener('touchend', this.listenerStop);
        this.stopEditing.emit()
    }

    getX(event: any): number {
        return (event.pageX !== undefined ? event.pageX : event.touches[0].pageX) - this.el.nativeElement.getBoundingClientRect().left - window.pageXOffset;
    }
    getY(event: any): number {
        return (event.pageY !== undefined ? event.pageY : event.touches[0].pageY) - this.el.nativeElement.getBoundingClientRect().top - window.pageYOffset;
    }

    ngOnDestroy() {
        this.stop();
    }
}

@Directive({
    selector: '[colorText]',
    host: {
        '(input)': 'changeInput($event.target.value)'
    },
    standalone: true
})

export class ColorTextDirective {
    @Output('newValue') newValue = new EventEmitter<any>();
    @Input('colorText') text: any;
    @Input('rg') rg: number;

    changeInput(value: string) {
        if (this.rg === undefined) {
            this.newValue.emit(value);
        } else {
            let numeric = parseFloat(value)
            if (!isNaN(numeric) && numeric >= 0 && numeric <= this.rg) {
                this.newValue.emit({ v: numeric, rg: this.rg });
            }
        }
    }
}

export class ColorPickerConfig {
    public showHueSlider:boolean = true;
    public showAlphaSlider:boolean = true;
    public showSelectedColor:boolean = true;
    public showColorTextInput:boolean = true;
    public height: number = 130;
}
