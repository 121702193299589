<div class="swatches">
    <div class="swatches__category" *ngFor="let category of categories">
        <div class="swatches__categoryName" *ngIf="category.name">
            {{category.name}}
        </div>
        <div class="swatches__colors" *ngIf="category.colors">
            <div    class="swatches__color"
                    [style.backgroundColor]="c.color"
                    *ngFor="let c of category.colors; let i = index;"
                    [ngClass]="{'swatches__color--active': toRgba(c.color) === rgbaColor, 'swatches__color--last': i === category.colors.length - 1 }"
                    (mousedown)="selectColor(c.color, true, $event)">
                    <ui-svg-icon class="checkmark-icon" icon="checkmark-large"></ui-svg-icon>
            </div>
        </div>
        <div class="swatches__column" *ngFor="let column of category.columns; let columnIndex = index;">
            <div    class="swatches__color"
                    [style.backgroundColor]="c.color"
                    *ngFor="let c of column.colors; let i = index;"
                    [ngClass]="{'swatches__color--active': toRgba(c.color) === rgbaColor, 'swatches__color--last': i === column.colors.length - 1 || columnIndex === category.columns.length - 1 }"
                    (mousedown)="selectColor(c.color, true, $event)">
                    <ui-svg-icon class="checkmark-icon" icon="checkmark-large"></ui-svg-icon>
            </div>
        </div>

    </div>
</div>
